var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.show,
            expression: "show",
          },
        ],
        ref: "loginView",
        class: [_vm.$style.loginView].concat(_vm.classList, [
          _vm.registerText === "注册后，立即使用扩大图"
            ? _vm.$style.loginView_Ai
            : "",
        ]),
        on: {
          click: function ($event) {
            if ($event.target !== $event.currentTarget) {
              return null
            }
            return _vm.closeDialog.apply(null, arguments)
          },
          wheel: _vm.handleWheel,
        },
      },
      [
        _c("div", {
          class: _vm.$style.closeDialog,
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.closeDialog.apply(null, arguments)
            },
          },
        }),
        _vm._v(" "),
        _c("div", { class: _vm.$style.loginWrapper }, [
          _c(
            "div",
            {
              class: [
                _vm.$style.characteristic,
                _vm.registerText === "立即找软件" ? _vm.$style.softBg : "",
                "font-regular",
              ],
            },
            [
              _c("div", { class: _vm.$style.wrapperTop }, [
                _vm.registerText !== "立即找软件"
                  ? _c("h3", { class: [_vm.$style.title] }, [
                      _vm._v("欢迎来到大作"),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("h5", { class: [_vm.$style.subtitle] }, [
                  _vm._v(_vm._s(_vm.registerText)),
                ]),
                _vm._v(" "),
                _c(
                  "ul",
                  { class: _vm.$style.characterList },
                  _vm._l(_vm.list, function (item, index) {
                    return _c(
                      "li",
                      { key: index, class: [_vm.$style.item, "font-regualr"] },
                      [
                        item.icon ? _c("span") : _vm._e(),
                        _vm._v(_vm._s(item.text) + "\n            "),
                      ]
                    )
                  }),
                  0
                ),
              ]),
              _vm._v(" "),
              _vm.registerText === "注册后，立即使用扩大图"
                ? _c("div", { class: _vm.$style.secondaryTitle }, [
                    _c("span", [_vm._v("找灵感")]),
                    _vm._v(" "),
                    _c("span", [_vm._v("上大作")]),
                  ])
                : _c("div", { class: _vm.$style.secondaryTitle }, [
                    _vm._v("找灵感  上大作"),
                  ]),
            ]
          ),
          _vm._v(" "),
          !_vm.$store.state.isServer
            ? _c(
                "div",
                {
                  class: [
                    _vm.$style.loginBox,
                    _vm.isNewLogin ? _vm.$style.newLoginCss : "",
                    _vm.registerText === "立即找软件" ? _vm.$style.softBox : "",
                  ],
                },
                [
                  _c("div", { class: _vm.$style.iconBox }, [
                    _vm.registerText === "立即找软件"
                      ? _c(
                          "div",
                          { class: [_vm.$style.rightH2, "font-bold"] },
                          [
                            _vm._v(
                              "\n              1000+设计必备专业软件库\n          "
                            ),
                          ]
                        )
                      : _c("img", {
                          attrs: {
                            src:
                              _vm.process.env.CDN_DZ_PATH +
                              "/bbw/wxregister3.svg",
                            alt: "",
                          },
                        }),
                    _vm._v(" "),
                    !_vm.isNewLogin
                      ? _c(
                          "div",
                          {
                            class: [
                              _vm.registerText === "立即找软件"
                                ? _vm.$style.softBlock
                                : "",
                            ],
                          },
                          [
                            _vm.registerText !== "立即找软件"
                              ? _c("p", { class: _vm.$style.subTitle }, [
                                  _vm._v("找灵感  上大作"),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "p",
                              { class: [_vm.$style.userNum, "font-regular"] },
                              [_vm._v(_vm._s(_vm.userNum) + "万设计师正在使用")]
                            ),
                            _vm._v(" "),
                            _vm.registerText === "立即找软件"
                              ? _c("p", { class: _vm.$style.rightH3 }, [
                                  _c("span", { class: ["font-medium"] }, [
                                    _vm._v("设计必备专业软件"),
                                  ]),
                                  _c("span", { class: ["font-bold"] }, [
                                    _vm._v("免费下载"),
                                  ]),
                                ])
                              : _vm._e(),
                          ]
                        )
                      : _c(
                          "div",
                          [_vm._t("default", null, { userNum: _vm.userNum })],
                          2
                        ),
                  ]),
                  _vm._v(" "),
                  _c(_vm.loginMode, {
                    tag: "component",
                    attrs: {
                      isShowGoNewLogin: _vm.isShowGoNewLogin,
                      webSiteName: _vm.webSiteName,
                      statementUrl: _vm.statementUrl,
                      pageUrl: _vm.pageUrl,
                      statusShow: _vm.show,
                      statementName: _vm.statementName,
                      privacyName: _vm.privacyName,
                      privacyUrl: _vm.privacyUrl,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }